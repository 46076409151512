import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import dynamic from 'next/dynamic'
import type { GetStaticPropsContext } from 'next'
import { Grid, SxProps, styledComponent, useTheme, useMediaQuery } from '@hermes/web-components'
import { useSpecialistPracticesContacts } from '../../../hooks/useSpecialistPractices'
import { useVisibilityComponents } from '../../../hooks/useVisibilityComponents'
import { useTopLevelKeywords } from '../../../hooks/useKeywordsMapper'
import { ProfileContextProvider } from '../../../providers/AppData/Profile'
import { useRouter } from 'next/router'
import OtherTopRatedPractices from '../../../components/profiles/practice/OtherTopRatedPractices'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import {
  BreadcrumbObject,
  ProfileKeyword,
  Statistic,
  PracticeProfile,
  SpecialistProfile,
  ProfileReview,
  ProfilePeerRecommendation,
  Percentage,
  Keywords
} from '../../../types'
import { capitalise } from '../../../utils/strings'
import { Breadcrumb } from '../../../types/breadcrumbs'
import useBreadCrumbsSchema from '../../../hooks/useBreadcrumbsSchema'
import Breadcrumbs from '../../../components/Breadcrumbs'
import {
  getSpecialistBySlug,
  getSpecialistSlotsBySlug,
  getSpecialistReviews,
  getSpecialistLatestReview,
  getSpecialistPeerRecommendations,
  getSpecialistLastPeerRecommendation,
  getNearBy,
  getBreadcrumbByLocationId
} from '../../../api/specialist'
import { SearchReviewsProvider } from '../../../providers/SearchReviews'
import { SearchPeerRecommendationsContextProvider } from '../../../providers/SearchPeerRecommendations'
import { AppDataContext } from '../../../providers/AppData'
import SpecialistTrustBanner from '../../../components/profiles/specialist/TrustBanner'
import PageMeta from '../../../components/ProfileMeta'
import withError from '../../../components/withError'
import useStatistic from '../../../hooks/useStatistic'
import useSpecialistProfileMeta from '../../../hooks/useSpecialistProfileMeta'
import useSpecialistSchema from '../../../hooks/useSpecialistSchema'
import { tranformSpecialistProfileResponse } from '../../../utils/transformSpecialistProfile'
import { transformSpecialistBookingSlots } from '../../../utils/transformSpecialistBookingSlots'
import { checkProfileSlugRedirect } from '../../../utils/checkProfileSlugRedirect'
import SectionWrapper from '../../../components/profiles/SectionWrapper'
import { SpecialistPlans, limitReviews } from '../../../constants/profile'
import { filteringProfileDataByPlan } from '../../../utils/filteringDataByPlan'
import getLanguageFromLocale from '../../../utils/getLanguageFromLocale'
import { useGetContactsData } from '../../../hooks/useGetProfileData'
import ProfileIntro from '../../../components/profiles/Intro'
import ProfileTabs from '../../../components/profiles/ProfileTabs'
import ProfileAbout from '../../../components/profiles/About'
import RatingBar from '../../../components/RatingBar'
import { BasicProfileAlert } from '../../../components/profiles/specialist/BasicProfileAlerts'
import Reviews from '../../../components/profiles/Reviews'
import SpecialistRecommendations from '../../../components/specialist/SpecialistRecommendations'
import { breadcrumbsUrlBuilder } from '../../../utils/breadcrumbsUrlBuilder'
import SpecialistWorkingHours from '../../../components/specialist/SpecialistWorkingHours'
import MediaCenter from '../../../components/profiles/Media'
import FAQ from '../../../components/profiles/specialist/FAQ'
import OtherTopRated from '../../../components/profiles/specialist/OtherTopRated'
import { BookingCalendarProvider, useBookingCalendar } from '../../../providers/BookingCalendar'
import { BookingSlot } from '../../../types/booking'
import getConfigVariable from '../../../utils/getConfigVariable'

const BookAppointmentModal = dynamic(() => import('../../../components/profiles/ProfileModal/BookAppointmentModal'), {
  ssr: false
})

const VideoPlayerModal = dynamic(() => import('../../../components/profiles/VideoPlayerModal'), { ssr: false })

const BookingCalendarApp = dynamic(() => import('../../../components/BookingCalendar/BookingCalendarApp'), {
  loading: () => <div style={{ height: '400px' }} />, // Reserve some space to prevent layout shifting
  ssr: false
})

const BookingCalendarModal = dynamic(() => import('../../../components/BookingCalendar/BookingCalendarModal'), {
  ssr: false
})

type SpecialistPageProps = {
  lang: string
  specialist: SpecialistProfile
  bookingSlots: BookingSlot[]
  latestReviews: ProfileReview[]
  latestReview: ProfileReview
  totalReviews: number
  topKeyword: ProfileKeyword
  topLocation: {
    label: string
    slug: string
  }
  errorCode?: number
  totalPeerRecommendations: number
  peerRecommendations: ProfilePeerRecommendation[]
  latestPeerRecommendation: ProfilePeerRecommendation
  nearBy?: {
    specialists: SpecialistProfile[]
    practices: PracticeProfile[]
  }
  isUnclaimed: boolean
  isProOrExpertPro: boolean
  isEntry: boolean
  percentage: Percentage
  breadcrumbs: Breadcrumb[]
  keywordNames: Keywords[]
}

const ReviewBarWrapper = styledComponent(Grid)(() => ({
  position: 'relative'
}))

const IntroSectionWrapper = styledComponent(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(13),
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(18)
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(18)
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(15)
  }
}))

const whiteColor = 'common.white'

// todo: fix complexity
// eslint-disable-next-line complexity
function SpecialistMainPage({
  specialist,
  bookingSlots,
  latestReviews,
  keywordNames,
  latestReview,
  totalReviews,
  peerRecommendations,
  latestPeerRecommendation,
  totalPeerRecommendations,
  nearBy,
  percentage,
  topKeyword,
  topLocation,
  breadcrumbs,
  isUnclaimed,
  isProOrExpertPro,
  isEntry
}: SpecialistPageProps) {
  const router = useRouter()
  const { language, isRtl } = useContext(AppDataContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))

  const [showBookingModal, setShowBookingModal] = useState(false)
  const translate = useTranslateMessage()
  const [bookingModalActiveButton, setBookingModalActiveButton] = useState<string | undefined>(undefined)
  const mediaCenterRef = useRef<HTMLDivElement>(null)
  const [videoPlayerVisibility, setVideoPlayerVisibility] = useState(false)
  const profileVideo = specialist.media?.filter((media) => media.type === 'profile' && media.enabled)[0]
  const profileName = useMemo(() => {
    let profileName = ''
    if (specialist.title[language]) {
      profileName = specialist.title[language] + ' '
    }
    return profileName + specialist.fullName[language]
  }, [language, specialist.fullName, specialist.title])
  const visibilityComponents = useVisibilityComponents({ specialist })
  const introVideoAvailable = useMemo(
    () => Boolean(!isUnclaimed && visibilityComponents.introductionVideoAvailable && profileVideo),
    [isUnclaimed, profileVideo, visibilityComponents.introductionVideoAvailable]
  )
  const publications = specialist.media?.filter((media) => media.type === 'publication' && media.enabled)
  const specialistSuffix = specialist.title[language]?.length > 1 ? specialist.title[language] : undefined
  const contacts = useGetContactsData({ specialistData: specialist })

  const toggleBookingModal = (button?: string, interactionLabel?: string) => {
    if (interactionLabel?.length) {
      window.dataLayer.push({
        event: 'prf_pg_cont_button',
        cont_prf_name: specialist.fullName[language],
        cont_prf_type: 'Specialist',
        cont_interaction_label: interactionLabel,
        cont_prf_uuid: specialist?.externalId
      })
    }
    setBookingModalActiveButton(button)
    setShowBookingModal((prev: boolean) => !prev)
  }

  const pageMeta = useSpecialistProfileMeta(specialist)
  const specSchema = useSpecialistSchema(specialist)
  const mappedBreadcrumbs = [
    ...breadcrumbs.slice(0, -1),
    {
      label: translate('search.breadcrumb_location', {
        name:
          topKeyword?.id === 1
            ? translate('search.all_specialists')
            : (topKeyword?.practitioner
                ? `${topKeyword?.practitioner?.[language] || topKeyword?.practitioner?.en}s`
                : topKeyword?.name[language]) || '',
        location: capitalise(decodeURIComponent(decodeURI(breadcrumbs[breadcrumbs.length - 1]?.label || language)))
      }),
      url: breadcrumbs[breadcrumbs.length - 1]?.url,
      id: breadcrumbs[breadcrumbs.length - 1]?.id
    }
  ]

  const pageSchema = useBreadCrumbsSchema(specSchema, mappedBreadcrumbs)

  const { practicesWithContactDetails } = useSpecialistPracticesContacts(specialist.practices)

  const { reviewStatistic, peerRecommendationStatistic, mergedStatistic } = useStatistic(specialist.statistic)

  const showBookButton = contacts.some((contact) => contact.externalBookingLink?.length)
  const showEmailButton = contacts.some((contact) => contact.email?.length)
  const showPhoneButton = contacts.some((contact) => contact.phone?.length)

  const hideBookAppointment = !showBookButton && !showEmailButton && !showPhoneButton

  const keywords = useTopLevelKeywords(specialist.keywords || [])

  const topKeywordName = topKeyword?.name[language].toLowerCase() || ''

  const maxTopKeywordLength = (() => {
    if (language === 'de') {
      return 10
    } else {
      return 20
    }
  })()

  const topKeywordLengthIsTooLong = topKeywordName.length > maxTopKeywordLength

  const otherSpecialistsButtonText = translate(
    topKeywordLengthIsTooLong ? 'other.specialists.buttonText.withoutKeyword' : 'other.specialsts.buttonText',
    {
      keyword: topKeywordLengthIsTooLong ? '' : topKeywordName,
      location: ''
    }
  )

  const otherPracticesButtonText = translate(
    topKeywordLengthIsTooLong ? 'other.practices.buttonText.withoutKeyword' : 'other.practices.buttonText',
    {
      keyword: topKeywordLengthIsTooLong ? '' : topKeywordName,
      location: ''
    }
  )

  const urlKeyword = topKeyword?.slug?.[0] || 'all'

  const otherSpecialistSearchUrl = `/${router.locale}/find/${urlKeyword}/${topLocation?.slug}/specialists`
  const otherPracticesSearchUrl = `/${router.locale}/find/${urlKeyword}/${topLocation?.slug}/practices`

  const profileAnalytics = {
    profile: {
      prf_type: specialist.plan,
      prf_practice_count: specialist.practices.length,
      prf_book_button: showBookButton,
      prf_contact_email: showEmailButton,
      prf_contact_phone: showPhoneButton,
      prf_booking_link: showBookButton,
      prf_media_centre: visibilityComponents.media,
      prf_uuid: specialist.externalId,
      prf_specialty: keywords,
      prf_practice_type: undefined,
      // false for now as described in doc
      prf_facilities: false
    },
    review: {
      rvw_count: specialist.reviewsTotal?.toString() || '0',
      vw_score: specialist.averageRating?.toString() || '0'
    }
  }

  const handleRouteChange = (url: string) => {
    if (url === `/${router.locale}${router.asPath}`) {
      window.dataLayer.push({
        event: 'prf_spa_page_change_ready',
        ...profileAnalytics
      })
    }
  }

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        ...profileAnalytics,
        practice_ids: specialist.practices.map((p) => p.externalId) || []
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  const sortedLanguages = specialist.languages
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    .sort((a, b) => {
      if (a.isoCode === language) {
        return -1
      }
      if (b.isoCode === language) {
        return 1
      }
      return 0
    })

  const bookingCalendarSpecialistProfile = useMemo(
    () => ({
      id: specialist.id,
      name: specialist.fullName[language],
      rating: specialist.averageRating,
      reviewsNumber: totalReviews,
      specialty: keywords,
      title: specialist.title[language],
      imageSrc: specialist.images.logo,
      pageLink: `/${router.locale}/specialist/${specialist.slug}`
    }),
    [
      keywords,
      language,
      router.locale,
      specialist.averageRating,
      specialist.fullName,
      specialist.id,
      specialist.images.logo,
      specialist.slug,
      specialist.title,
      totalReviews
    ]
  )

  const bookingCalendarSpecialistPractices = useMemo(
    () =>
      specialist.practices.map((specialistPractice) => ({
        id: specialistPractice.id,
        name: specialistPractice.name?.[language],
        rating: specialistPractice.reviews.overallExperience,
        reviewsNumber: specialistPractice.reviews?.reviewsTotal ?? 0,
        contactNumber: specialistPractice.contactDetailsPractice?.phone
      })),
    [language, specialist.practices]
  )

  const bookingCalendarSlots = useMemo(
    () =>
      specialist.practices.map((specialistPractice) => ({
        practiceId: specialistPractice.id,
        slots: bookingSlots.filter((f) => f.practice.id === specialistPractice.id)
      })),
    [specialist.practices, bookingSlots]
  )

  const bookingCalendarVisitReasons = useMemo(
    () => [
      { name: 'Select', value: null },
      ...specialist.keywords.map((keyword) => ({
        name: keyword.name?.[language],
        value: String(keyword.id)
      }))
    ],
    [language, specialist.keywords]
  )

  const RatingBarElement = ({ sx = {}, isBookingAvailable = false }: { sx?: SxProps; isBookingAvailable: boolean }) => {
    const bookingCalendarComponent = useMemo(
      () => (isBookingAvailable ? <BookingCalendarApp containerStyle={{ padding: 20 }} /> : null),
      [isBookingAvailable]
    )

    return (
      <ReviewBarWrapper sx={sx || {}} item xs={12} lg={5}>
        <RatingBar
          endAnchorRef={mediaCenterRef}
          profileName={specialist.fullName[language]}
          isBasic={specialist.basic}
          averageRating={specialist.averageRating}
          reviewsTotal={totalReviews}
          peerRecommendationsCount={totalPeerRecommendations}
          peerRecommendation={latestPeerRecommendation}
          onBookAppointment={toggleBookingModal}
          statistic={mergedStatistic}
          reviewStatistic={reviewStatistic}
          externalIdProfile={specialist.externalId}
          slug={specialist.slug}
          latestReview={latestReview}
          review={specialist.review}
          hideBookAppointment={hideBookAppointment && !practicesWithContactDetails?.length}
          isUnclaimed={isUnclaimed}
          type="specialist"
          contacts={contacts}
          bookingCalendarComponent={bookingCalendarComponent}
        />
      </ReviewBarWrapper>
    )
  }

  const ContactsSection = () => {
    const isBookingAvailable = !!bookingSlots.length

    return isMobile || isLaptop ? (
      <>
        <Grid item sx={{ p: '70px 16px 0 16px', backgroundColor: whiteColor }} xs={12} lg={7}>
          <ProfileIntro
            id={specialist.externalId}
            slug={specialist.slug}
            plan={specialist.plan}
            isBasic={specialist.basic}
            name={specialist.fullName[language]}
            suffix={specialistSuffix}
            image={specialist.images?.logo}
            keywords={specialist.keywords}
            yearsOfExperience={specialist.yearsOfExperience}
            practices={specialist.practices}
            isUnclaimed={isUnclaimed}
            isEntry={isEntry}
            videoConsultation={specialist.videoConsultation}
            introVideoAvailable={introVideoAvailable}
            profileAnalytics={profileAnalytics}
            setVideoPlayerVisibility={setVideoPlayerVisibility}
            connectionsCount={specialist.connectionsCount}
          />
        </Grid>
        <ProfileTabs specialist={specialist} isUnclaimed={isUnclaimed} />
        <RatingBarElement isBookingAvailable={isBookingAvailable} sx={{ pl: '16px !important' }} />
        {isBookingAvailable && (
          <BookingCalendarApp
            containerStyle={{ padding: '20px', backgroundColor: 'white', justifyContent: 'center' }}
          />
        )}
      </>
    ) : (
      <>
        <SectionWrapper sx={{ backgroundColor: { xs: whiteColor, lg: 'background.default' } }} id="overview">
          <IntroSectionWrapper container>
            <Grid item xs={12} lg={7}>
              <ProfileIntro
                id={specialist.externalId}
                slug={specialist.slug}
                plan={specialist.plan}
                isBasic={specialist.basic}
                name={specialist.fullName[language]}
                suffix={specialistSuffix}
                image={specialist.images?.logo}
                keywords={specialist.keywords}
                yearsOfExperience={specialist.yearsOfExperience}
                practices={specialist.practices}
                isUnclaimed={isUnclaimed}
                isEntry={isEntry}
                videoConsultation={specialist.videoConsultation}
                introVideoAvailable={introVideoAvailable}
                profileAnalytics={profileAnalytics}
                setVideoPlayerVisibility={setVideoPlayerVisibility}
                connectionsCount={specialist.connectionsCount}
              />
            </Grid>
            <RatingBarElement isBookingAvailable={isBookingAvailable} />
          </IntroSectionWrapper>
        </SectionWrapper>
        <ProfileTabs specialist={specialist} isUnclaimed={isUnclaimed} />
      </>
    )
  }

  const BookingModal = () => {
    const bookingCalendarContext = useBookingCalendar()

    return showBookingModal ? (
      <BookAppointmentModal
        isOpen
        onClose={toggleBookingModal}
        bookingModalActiveButton={bookingModalActiveButton}
        specialist={specialist}
        bookingCalendarContext={bookingCalendarContext}
        hideProfileHeader={true}
      />
    ) : null
  }

  return (
    <>
      <PageMeta isProfile {...pageMeta}>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: pageSchema }} />
      </PageMeta>
      <ProfileContextProvider specialist={specialist}>
        <SearchReviewsProvider
          language={language}
          keywordNames={keywordNames}
          reviews={latestReviews}
          currentLocation={breadcrumbs.slice(-1).pop()}
          profileId={specialist.id}
          totalCount={totalReviews}
        >
          <BookingCalendarProvider
            specialistProfile={bookingCalendarSpecialistProfile}
            toggleBookingModal={toggleBookingModal}
            practices={bookingCalendarSpecialistPractices}
            slots={bookingCalendarSlots}
            visitReasons={bookingCalendarVisitReasons}
            insurances={specialist.insurers}
          >
            <ContactsSection />

            {visibilityComponents.about && (
              <SectionWrapper withPadding sx={{ backgroundColor: whiteColor }}>
                <Grid container>
                  {isUnclaimed && (
                    <Grid item lg={7} md={12} xs={12} sx={{ mb: 2 }}>
                      <BasicProfileAlert
                        id={specialist.externalId}
                        slug={specialist.slug}
                        plan={specialist.plan}
                        name={specialist.fullName[language]}
                      />
                    </Grid>
                  )}
                  <Grid item md={12} lg={7} xs={12}>
                    <ProfileAbout
                      isBasic={specialist.basic}
                      about={specialist.about?.[language]}
                      keywords={specialist.keywords}
                      registrationBodies={specialist.registrationBodies}
                      insurers={specialist.insurers}
                      languages={sortedLanguages}
                      consultationFees={specialist.consultationFees}
                      statistic={mergedStatistic}
                      name={profileName}
                      nearBy={nearBy?.specialists}
                      isEntry={isEntry}
                      qualifications={specialist.education?.[language] as string}
                      otherSpecialistsButtonText={otherSpecialistsButtonText}
                      otherSpecialistSearchUrl={otherSpecialistSearchUrl}
                      isUnclaimed={isUnclaimed}
                      publications={publications}
                    />
                  </Grid>
                  {specialist.basic && (nearBy?.specialists || nearBy?.practices) && (
                    <>
                      {!isEntry && !isUnclaimed && (
                        <Grid item md={12} lg={7} xs={12} sx={{ mt: 2 }}>
                          <OtherTopRated
                            specialists={nearBy?.specialists}
                            headerText={translate('other.specialsts.headerText')}
                            buttonText={otherSpecialistsButtonText}
                            isRtl={isRtl}
                            shortButtonText={translate('other.specialsts.shortButtonText')}
                            buttonLink={otherSpecialistSearchUrl}
                          />
                        </Grid>
                      )}
                      <Grid item md={12} lg={7} xs={12} sx={{ mt: 2 }}>
                        <OtherTopRatedPractices
                          practices={nearBy?.practices}
                          headerText={translate('other.practices.headerText')}
                          buttonText={otherPracticesButtonText}
                          isRtl={isRtl}
                          shortButtonText={translate('other.practices.shortButtonText')}
                          buttonLink={otherPracticesSearchUrl}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </SectionWrapper>
            )}
            {visibilityComponents.location && (
              <SectionWrapper
                withPadding
                sx={{
                  background: 'rgba(17, 41, 113, 0.8)'
                }}
                id="locations"
              >
                <Grid container>
                  <Grid item xs={12} md={12} lg={7}>
                    <SpecialistWorkingHours
                      id={specialist.externalId}
                      slug={specialist.slug}
                      profileName={profileName}
                      plan={specialist.plan}
                      practices={specialist.practices}
                      videoConsultation={specialist.videoConsultation}
                      staticMapImage={specialist.staticMapImage}
                      basic={specialist.basic}
                      hideBookAppointment={hideBookAppointment}
                      onBookAppointment={toggleBookingModal}
                      isUnclaimed={isUnclaimed}
                      onGetInTouch={toggleBookingModal}
                    />
                  </Grid>
                </Grid>
              </SectionWrapper>
            )}
            {visibilityComponents.reviews && (
              <SectionWrapper withPadding id="reviews">
                <Grid container>
                  <Grid item lg={7} xs={12}>
                    <Reviews
                      id={specialist.externalId}
                      plan={specialist.plan}
                      statistic={reviewStatistic}
                      slug={specialist.slug}
                      isBasic={specialist.basic}
                      name={specialist.fullName[language]}
                      isUnclaimed={isUnclaimed}
                      isProOrExpertPro={isProOrExpertPro}
                      isEntry={isEntry}
                      percentage={percentage}
                    />
                  </Grid>
                </Grid>
              </SectionWrapper>
            )}
            <SectionWrapper sx={{ backgroundColor: 'primary.main' }}>
              <Grid container>
                <Grid item xs={12} md={12} lg={7}>
                  <SpecialistTrustBanner />
                </Grid>
              </Grid>
            </SectionWrapper>
            {visibilityComponents.peerRecommendations && (
              <SectionWrapper withPadding sx={{ backgroundColor: 'background.light2' }} id="endorsements">
                <Grid container>
                  <Grid item xs={12} md={12} lg={7}>
                    <SearchPeerRecommendationsContextProvider
                      recommendations={peerRecommendations}
                      totalCount={totalPeerRecommendations}
                      profileId={specialist.id}
                    >
                      <SpecialistRecommendations statistics={peerRecommendationStatistic} />
                    </SearchPeerRecommendationsContextProvider>
                  </Grid>
                </Grid>
              </SectionWrapper>
            )}
            {visibilityComponents.media && (
              <section id="media">
                <div ref={mediaCenterRef}>
                  <MediaCenter
                    isBasic={specialist.basic}
                    profileName={profileName}
                    profileImage={specialist.images?.logo}
                    profileAnalytics={profileAnalytics}
                    isUnclaimed={isUnclaimed}
                    posts={specialist.media}
                    setVideoPlayerVisibility={setVideoPlayerVisibility}
                  />
                </div>
              </section>
            )}
            {visibilityComponents.faq && (
              <SectionWrapper withPadding sx={{ backgroundColor: whiteColor }} id="faq">
                <Grid container>
                  <Grid item xs={12} md={12} lg={7}>
                    <FAQ
                      specialist={specialist}
                      onGetInTouch={() => toggleBookingModal('all', 'get in touch')}
                      hideGetInTouch={!practicesWithContactDetails?.length}
                    />
                  </Grid>
                  <Grid item xs={12} lg={5} />
                </Grid>
              </SectionWrapper>
            )}
            {Boolean(breadcrumbs.length) && breadcrumbs.length >= 1 && (
              <SectionWrapper withPadding sx={{ backgroundColor: 'background.light' }} id="breadcrumbs">
                <Grid container>
                  <Grid item xs={12} md={12} lg={7}>
                    <Breadcrumbs items={mappedBreadcrumbs} />
                  </Grid>
                </Grid>
              </SectionWrapper>
            )}
            <BookingModal />
            <BookingCalendarModal />
          </BookingCalendarProvider>
        </SearchReviewsProvider>
      </ProfileContextProvider>

      {profileVideo && videoPlayerVisibility && (
        <VideoPlayerModal
          open
          specialistId={specialist.id}
          mediaId={profileVideo?.id}
          url={profileVideo?.link}
          onClose={() => setVideoPlayerVisibility(false)}
        />
      )}
    </>
  )
}

// todo: fix complexity
// eslint-disable-next-line complexity
export async function getStaticProps(context: GetStaticPropsContext) {
  const lang = context.locale as string
  const slug = context.params?.slug as string
  const language = getLanguageFromLocale(lang)

  const slugToRedirect = checkProfileSlugRedirect(slug)

  if (slugToRedirect) {
    return {
      redirect: {
        destination: `/${lang}/specialist/${slugToRedirect}`,
        statusCode: 301
      }
    }
  }

  const specialistResponse = await getSpecialistBySlug({
    lang,
    slug
  })

  if (specialistResponse.statusCode || specialistResponse.message) {
    return {
      props: {
        errorCode: specialistResponse.statusCode || 500
      },
      notFound: true
    }
  }
  const { result: specialist } = specialistResponse

  const topLevelKeyword =
    specialist.keywords.find((keyword) => keyword.isTopLevelChild && keyword.keywordType === 'specialty') || null

  let topStat: Statistic | null = null

  specialist.statistic.forEach((obj) => {
    if (!topStat || obj.tagCount > topStat.tagCount) {
      topStat = obj
    }
  })

  const topKeyword: ProfileKeyword | null =
    topLevelKeyword || specialist.keywords.find((k) => k.keywordId === topStat?.tagId) || null

  const sortedPractices = [...specialist.practices]
    .sort((a, b) => b.reviews.reviewsTotal - a.reviews.reviewsTotal)
    .sort((a, b) => b.practiceSortOrder - a.practiceSortOrder)
    .filter((x) => x.slug !== 'video-consultation')

  function transformObjectToArray(obj: BreadcrumbObject) {
    const resultArray: { label: string; url: string; id: number; slug: string }[] = []

    function transform(objItem: BreadcrumbObject) {
      const objForTransformFunc = () => {
        if (!sortedPractices.length && Array.isArray(objItem)) {
          return objItem.find((x) => x.country)
        }
        return Array.isArray(objItem) ? objItem[objItem.length - 1] : objItem
      }
      const objForTransform = objForTransformFunc()
      const name = objForTransform?.name?.[language] || objForTransform?.name?.en

      const currentItem = {
        label: name,
        url: breadcrumbsUrlBuilder({ lang, topKeyword, objForTransform, name, profileType: 'specialists' }),
        id: objForTransform.id,
        slug: objForTransform.slug
      }
      if (name) {
        resultArray.push(currentItem)
      }

      if (objForTransform.parent) {
        transform(objForTransform.parent)
      }
    }

    transform(obj)

    return resultArray
  }

  const LocationId = sortedPractices?.[0]?.address?.LocationId
  const breadcrumbsResponse = await getBreadcrumbByLocationId({
    lang,
    id: LocationId
  })

  const isBasic = specialist.basic
  const isProOrExpertPro =
    specialist?.plan?.includes(SpecialistPlans.EXPERT) || specialist?.plan?.includes(SpecialistPlans.EXPERT_PRO)
  const isUnclaimed =
    specialist.plan?.includes(SpecialistPlans.UNCLAIMED) || specialist.plan?.includes(SpecialistPlans.NO_PLAN)
  const isEntry = specialist.plan?.includes(SpecialistPlans.ENTRY)

  const promises: Promise<any>[] = [
    getSpecialistPeerRecommendations({
      id: specialist.id,
      lang,
      limit: 4
    }),
    getSpecialistLastPeerRecommendation({
      id: specialist.id,
      lang
    })
  ]

  if (isBasic) {
    promises.push(
      getNearBy({
        slug,
        lang
      })
    )
  } else {
    promises.push(
      ...[
        getSpecialistReviews({
          id: specialist.id,
          tenant: lang,
          language,
          limit: limitReviews
        }),
        getSpecialistLatestReview({
          id: specialist.id,
          tenant: lang,
          language
        })
      ]
    )
  }

  const filteredSpecialistData = filteringProfileDataByPlan({ specialist }) as SpecialistProfile
  const [{ result: peerRecommendations, totalCount: totalPeerRecommendations }, latestPeerRecommendation, ...restData] =
    await Promise.all(promises)

  const breadcrumbsArray = transformObjectToArray(breadcrumbsResponse).reverse() || []

  const topLocationFromBreadcrumbs = Array.from(breadcrumbsArray).reverse()[0] || null

  let bookingSlots: Array<BookingSlot> = []

  const stage = getConfigVariable('STAGE')

  if (stage === 'development') {
    const bookingSlotsResponse = await getSpecialistSlotsBySlug({ lang, slug })

    if ('availability' in bookingSlotsResponse) {
      bookingSlots = transformSpecialistBookingSlots(bookingSlotsResponse)
    }
  }

  const data = {
    lang,
    specialist: tranformSpecialistProfileResponse(filteredSpecialistData, language),
    bookingSlots,
    slug: context.params?.slug,
    breadcrumbs: topKeyword ? breadcrumbsArray : [],
    peerRecommendations: peerRecommendations || null,
    totalPeerRecommendations: totalPeerRecommendations || 0,
    keywordNames: restData[0]?.keywordNames || null,
    topKeyword,
    topLocation: topLocationFromBreadcrumbs || null,
    latestPeerRecommendation:
      (Array.isArray(latestPeerRecommendation) ? latestPeerRecommendation[0] : latestPeerRecommendation) || null,
    isUnclaimed,
    isProOrExpertPro,
    isEntry
  }
  if (isBasic) {
    const [{ specialists, practices }] = restData

    return {
      props: {
        ...data,
        nearBy: { specialists: specialists?.data || null, practices: practices?.data || null }
      },
      revalidate: 60 * 60 * 8 // 8h
    }
  }
  const [{ result: reviews, totalCount }, latestReview] = restData

  return {
    props: {
      ...data,
      latestReviews: Array.isArray(reviews) ? reviews : [],
      totalReviews: totalCount,
      topKeyword,
      latestReview: Array.isArray(latestReview) ? latestReview[0] || null : latestReview || null,
      percentage: restData[0]?.percentage || null
    },
    revalidate: 60 * 60 * 8 // 8h
  }
}

export function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking'
  }
}

const SpecialistPage = withError<SpecialistPageProps>(SpecialistMainPage)

export default SpecialistPage
