import { SCREENS, DateString, SlotType, TimeString, PracticeProfileType, InsuranceType } from '@hermes/booking-calendar'
import { FC, createContext, useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { LOCATION_MODAL_VARIANTS, MODAL_VARIANTS, ProviderProps, ReturnTypeProps, StateProps } from './types'
import { bookSpecialistSlot } from '../../api/specialist'

const BookingCalendarContext = createContext<ReturnTypeProps | undefined>(undefined)

const findPracticeSlots = (slots: Array<{ practiceId: number; slots: SlotType[] }>, practice?: PracticeProfileType) => {
  if (!practice) {
    return []
  }
  return slots?.find?.((f) => f.practiceId === practice?.id)?.slots ?? []
}

const findDefaultPractice = (
  slots: Array<{ practiceId: number; slots: SlotType[] }> = [],
  practices: Array<PracticeProfileType> = []
) => {
  for (const practice of practices) {
    const practiceSlots = findPracticeSlots(slots, practice)
    if (practiceSlots.length) {
      return practice
    }
  }

  return undefined
}

export const BookingCalendarProvider: FC<ProviderProps> = ({
  slots = [],
  specialistProfile,
  practices,
  toggleBookingModal,
  visitReasons,
  children,
  insurances = []
}) => {
  const router = useRouter()

  const [state, setState] = useState<StateProps>({
    practiceProfile: findDefaultPractice(slots, practices),
    practices,
    specialistProfile,
    slots: findPracticeSlots(slots, findDefaultPractice(slots, practices)),
    visitReasons,
    currentScreen: SCREENS.CALENDAR_PREVIEW,
    selectedInsurance: { value: null, label: 'Select' },
    selectedSlot: null,
    selectedDateString: undefined,
    selectedTimeString: undefined
  })

  const [locationModalVariant, setLocationModalVariant] = useState<LOCATION_MODAL_VARIANTS>(
    LOCATION_MODAL_VARIANTS.NONE
  )
  const [modalVariant, setModalVariant] = useState<MODAL_VARIANTS>(MODAL_VARIANTS.NONE)

  const [isFullScreenMode, setIsFullScreenMode] = useState(false)

  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false)

  useEffect(() => {
    setState((prev: StateProps) => ({
      ...prev,
      practice: practices[0] ?? prev.practiceProfile,
      slots: findPracticeSlots(slots, prev.practiceProfile ?? practices[0]),
      visitReasons: visitReasons ?? prev.visitReasons,
      specialist: specialistProfile ?? prev.specialistProfile
    }))
  }, [practices, slots, specialistProfile, visitReasons])

  const getPracticeSlots = useCallback(
    (practiceId: number) => {
      const practice = practices.find((p) => p.id === practiceId)
      return findPracticeSlots(slots, practice)
    },
    [slots, practices]
  )

  const displayPracticeSelector = useMemo(() => {
    let practicesWithSlotsCount = 0
    for (const slot of slots) {
      const practiceSlots = getPracticeSlots(slot.practiceId)
      if (practiceSlots.length) {
        practicesWithSlotsCount += 1
      }
      if (practicesWithSlotsCount > 1) {
        return true
      }
    }
    return false
  }, [getPracticeSlots, slots])

  const handleExpandButtonClick = useCallback(() => {
    setModalVariant((prev) =>
      prev === MODAL_VARIANTS.FULL_SCREEN ? MODAL_VARIANTS.SIDE_MODAL : MODAL_VARIANTS.FULL_SCREEN
    )
    setIsFullScreenMode((prev) => !prev)
  }, [])

  const handleInsuranceSelectionClick = useCallback(
    (insurance?: InsuranceType) =>
      setState((prev) => ({
        ...prev,
        selectedInsurance: insurance ? { value: insurance.id, label: insurance.name } : { value: null, label: 'Select' }
      })),
    []
  )

  const handleDateSelectionClick = useCallback((dateString?: DateString) => {
    if (specialistProfile && dateString)
      window.dataLayer.push({
        event: 'prf_pg_book_calndr_interaction',
        cont_prf_name: specialistProfile.name || '',
        cont_prf_type: 'Specialist',
        cont_prf_uuid: specialistProfile.id.toString(),
        cont_interaction_label: 'date selected',
        cont_prf_step: 'date selected'
      })
    setState((prev) => ({ ...prev, selectedDateString: dateString }))
  }, [])

  const handleTimeSelectionClick = useCallback((timeString?: TimeString, triggerSource?: string) => {
    const isProfile = triggerSource === 'profile'
    if (specialistProfile)
      window.dataLayer.push({
        event: 'prf_pg_book_calndr_interaction',
        cont_prf_name: specialistProfile.name || '',
        cont_prf_type: 'Specialist',
        cont_prf_uuid: specialistProfile.id.toString(),
        cont_interaction_label: isProfile ? 'time slot confirm ' : 'time slot selected',
        cont_prf_step: isProfile ? 'profile page' : 'time slot selected'
      })

    setState((prev) => ({ ...prev, selectedTimeString: timeString }))
  }, [])

  const handleSlotSelectionClick = useCallback(
    (slot?: SlotType) => setState((prev) => ({ ...prev, selectedSlot: slot ?? null })),
    []
  )

  const changeScreen = useCallback(
    (screen: number) => {
      switch (screen) {
        case SCREENS.CALENDAR_PREVIEW: {
          setModalVariant(MODAL_VARIANTS.NONE)
          handleDateSelectionClick(undefined)
          handleTimeSelectionClick(undefined)
          handleSlotSelectionClick(undefined)
          break
        }
        case SCREENS.DATE_SELECTION: {
          setModalVariant(isFullScreenMode ? MODAL_VARIANTS.FULL_SCREEN : MODAL_VARIANTS.SIDE_MODAL)
          handleDateSelectionClick(undefined)
          handleTimeSelectionClick(undefined)
          handleSlotSelectionClick(undefined)
          break
        }
        case SCREENS.SLOT_SELECTION:
          setModalVariant(isFullScreenMode ? MODAL_VARIANTS.FULL_SCREEN : MODAL_VARIANTS.SIDE_MODAL)
          handleTimeSelectionClick(undefined)
          handleSlotSelectionClick(undefined)
          break
        case SCREENS.SLOT_NOT_AVAILABLE:
        case SCREENS.BOOKING_FORM:
        case SCREENS.SUCCESS_APPOINTMENT:
        default:
          setModalVariant(MODAL_VARIANTS.FULL_SCREEN)
      }

      setState((prev) => ({ ...prev, currentScreen: screen }))
    },
    [handleDateSelectionClick, handleSlotSelectionClick, handleTimeSelectionClick, isFullScreenMode]
  )

  const handleBookButtonClick = useCallback(
    async (formData: Record<string, any>) => {
      const success = await bookSpecialistSlot({
        data: formData,
        lang: router.locale as string,
        id: specialistProfile?.id as number
      })

      if (specialistProfile && practices)
        window.dataLayer.push({
          event: success ? 'prf_pg_book_calndr_confrm' : 'prf_pg_book_calndr_time_slot_error',
          cont_prf_name: specialistProfile.name || '',
          cont_prf_type: 'Specialist',
          cont_prf_uuid: specialistProfile.id.toString(),
          cont_enquired_at_name: practices[0].name,
          cont_enquired_at_uuid: practices[0].id.toString()
        })

      changeScreen(success ? SCREENS.SUCCESS_APPOINTMENT : SCREENS.SLOT_NOT_AVAILABLE)
    },
    [changeScreen, router.locale, specialistProfile?.id]
  )

  const handleContactRequestClick = useCallback(() => {
    setLocationModalVariant(LOCATION_MODAL_VARIANTS.CONTACT)
    toggleBookingModal()
  }, [toggleBookingModal])

  const handleProfileSelectionClick = useCallback(() => {
    if (specialistProfile)
      window.dataLayer.push({
        event: 'prf_pg_book_calndr_interaction',
        cont_prf_name: specialistProfile.name || '',
        cont_prf_type: 'Specialist',
        cont_prf_uuid: specialistProfile.id.toString(),
        cont_interaction_label: 'location drop down click',
        cont_prf_step: 'Value of either: profile page, time slot, time slot selected, date select, date selected'
      })

    if (displayPracticeSelector) {
      setLocationModalVariant(LOCATION_MODAL_VARIANTS.PROFILE_SELECTION)
      toggleBookingModal()
    } else {
      changeScreen(SCREENS.DATE_SELECTION)
    }
  }, [displayPracticeSelector, toggleBookingModal, changeScreen])

  const closeProfileSelectionModal = useCallback(() => {
    setLocationModalVariant(LOCATION_MODAL_VARIANTS.NONE)
    toggleBookingModal()
  }, [toggleBookingModal])

  const handleSlotNotAvailableBackClick = () => {
    if (specialistProfile)
      window.dataLayer.push({
        event: 'prf_pg_book_calndr_interaction',
        cont_prf_name: specialistProfile.name || '',
        cont_prf_type: 'Specialist',
        cont_prf_uuid: specialistProfile.id.toString(),
        cont_interaction_label: 'find another slot click',
        cont_prf_step: 'booking slot not avialable error'
      })

    changeScreen(SCREENS.CALENDAR_PREVIEW)
  }

  const handlePracticeChange = useCallback(
    (id: number) => {
      if (specialistProfile)
        window.dataLayer.push({
          event: 'prf_pg_book_calndr_interaction',
          cont_prf_name: specialistProfile.name || '',
          cont_prf_type: 'Specialist',
          cont_prf_uuid: specialistProfile.id.toString(),
          cont_interaction_label: 'book this practice',
          cont_prf_step: 'select location'
        })

      setState((prev) => {
        const selectedPractice = practices.find((f) => f.id === id)
        return {
          ...prev,
          practiceProfile: selectedPractice,
          slots: findPracticeSlots(slots, selectedPractice as PracticeProfileType)
        }
      })
    },
    [practices, slots]
  )

  const handleInsuranceSelectClick = useCallback(() => {
    setIsInsuranceModalOpen((prev) => !prev)
  }, [])

  const isBookingAvailable = useMemo(() => {
    const defaultPractice = findDefaultPractice(slots, practices)
    return !!defaultPractice
  }, [practices, slots])

  const handleShowMoreDatesClick = useCallback(() => {
    if (specialistProfile)
      window.dataLayer.push({
        event: 'prf_pg_book_calndr_interaction',
        cont_prf_name: specialistProfile.name || '',
        cont_prf_type: 'Specialist',
        cont_prf_uuid: specialistProfile.id.toString(),
        cont_interaction_label: 'more dates arrow',
        cont_prf_step: 'profile page'
      })

    changeScreen(SCREENS.DATE_SELECTION)
  }, [changeScreen])

  const handleShowMoreSlotsClick = useCallback(() => {
    if (specialistProfile)
      window.dataLayer.push({
        event: 'prf_pg_book_calndr_interaction',
        cont_prf_name: specialistProfile.name || '',
        cont_prf_type: 'Specialist',
        cont_prf_uuid: specialistProfile.id.toString(),
        cont_interaction_label: 'more time slots',
        cont_prf_step: 'profile page'
      })

    changeScreen(SCREENS.SLOT_SELECTION)
  }, [changeScreen])

  const handleViewAllAvailabilityClick = useCallback(() => {
    if (specialistProfile)
      window.dataLayer.push({
        event: 'prf_pg_book_calndr_interaction',
        cont_prf_name: specialistProfile.name || '',
        cont_prf_type: 'Specialist',
        cont_prf_uuid: specialistProfile.id.toString(),
        cont_interaction_label: 'view all availability',
        cont_prf_step: 'profile page'
      })

    changeScreen(SCREENS.SLOT_SELECTION)
  }, [changeScreen])

  return (
    <BookingCalendarContext.Provider
      value={{
        state,
        insurances,
        isFullScreenMode,
        isBookingAvailable,
        isInsuranceModalOpen,
        modalVariant,
        locationModalVariant,
        closeProfileSelectionModal,
        setModalVariant,
        getPracticeSlots,
        displayPracticeSelector,
        handleBookButtonClick,
        handleExpandButtonClick,
        handleContactRequestClick,
        handleInsuranceSelectionClick,
        handleDateSelectionClick,
        handleTimeSelectionClick,
        handleProfileSelectionClick,
        handleSlotNotAvailableBackClick,
        handleSlotSelectionClick,
        handlePracticeChange,
        handleInsuranceSelectClick,
        handleShowMoreDatesClick,
        handleShowMoreSlotsClick,
        handleViewAllAvailabilityClick,
        changeScreen
      }}
    >
      {children}
    </BookingCalendarContext.Provider>
  )
}

export const useBookingCalendar = () => {
  const context = useContext(BookingCalendarContext)

  if (!context) {
    throw new Error('useBookingCalendar must be used within a BookingCalendarProvider')
  }

  return context
}
